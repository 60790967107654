// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-form-submitted-js": () => import("./../../../src/pages/form-submitted.js" /* webpackChunkName: "component---src-pages-form-submitted-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-single-work-template-js": () => import("./../../../src/templates/single-work-template.js" /* webpackChunkName: "component---src-templates-single-work-template-js" */)
}

