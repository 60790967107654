// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
const React = require("react")
require('./src/components/global-styles/_global.scss')

const CurtainAnimationWrapper = (props) => {
  return (
    <>
      {props.children}
      <div className="curtain" id="curtain-animation" />
    </>
  )
}

// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {
  return <CurtainAnimationWrapper {...props}>{element}</CurtainAnimationWrapper>
}